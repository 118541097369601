import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import {
  autoTokenRefresh,
  getToken,
  isTokenNearToExpire,
  refreshToken,
} from "utils/authorizationUtils";
import { setCacheVal } from "utils/cacheUtils";

import envConfig from "./envConfig";
import LOCAL_STORAGE_CONSTANTS from "../constants/authorizationLocalStorage";

const httpLink = createHttpLink({
  uri: envConfig.graphSchemeUrl,
});

let isFirstRequest = false;
// Adds token to every graphQL request
const authLink = setContext(async (_, { headers }) => {
  const token = getToken();
  if (!!token && isTokenNearToExpire(token)) {
    const newToken = (await refreshToken())?.token;
    if (newToken) {
      setCacheVal(LOCAL_STORAGE_CONSTANTS.Token, newToken);
    }
  }

  if (!isFirstRequest) {
    isFirstRequest = true;
    autoTokenRefresh();
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : "",
    },
  };
});
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
