export const getCacheVal = (key: string) => {
  const strValue = window.localStorage.getItem(key);
  let value = null;
  try {
    if (strValue != null) {
      value = JSON.parse(strValue);
    }
  } catch (e) {
    value = strValue;
  }

  return value;
};

export const setCacheVal = (key: string, val: any) =>
  window.localStorage.setItem(key, JSON.stringify(val));

export const removeCacheVal = (key: string) =>
  window.localStorage.removeItem(key);
