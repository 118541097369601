import React, { createContext, useContext, useEffect, useState } from "react";

import {
  clearStorageTokenValues,
  getToken,
  isLoggedIn,
} from "utils/authorizationUtils";

type ContextState = {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  clearUserData: () => void;
  logout: () => void;
};

type Props = {
  children: React.ReactNode;
};

const AuthContext = createContext({} as ContextState);

function AuthProvider({ ...props }: Props) {
  const [token, setToken] = useState<ContextState["token"]>(null);

  useEffect(() => {
    const tokenFromStore = getToken();
    if (isLoggedIn() && !token && tokenFromStore) {
      setToken(getToken());
    }
  }, [token]);

  const clearUserData = () => {
    setToken(null);
  };
  const logout = () => {
    clearStorageTokenValues();
    setToken(null);
  };
  const value = {
    clearUserData,
    logout,
    setToken,
    token,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUserContext must be used within a UserProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };
