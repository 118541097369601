import { RefObject } from "react";

import {
  ColDef,
  FirstDataRenderedEvent,
  RowDataUpdatedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { ComponentDto } from "graphql/main";

export const hideEmptyColumns = (props: {
  event: RowDataUpdatedEvent;
  gridRef: RefObject<AgGridReact>;
  autoSizeColumns?: boolean;
}) => {
  const { columnApi, api } = props.gridRef.current || {};

  if (!columnApi || !api) return;

  const columns = props.event.columnApi.getColumns();
  const rowNodes = props.event.api.getRenderedNodes();

  columns?.forEach((column) => {
    const columnEmpty = rowNodes.every((rowNode) => {
      const value = api.getValue(column.getColId(), rowNode);

      return value === undefined || value === null;
    });

    column.setVisible(!columnEmpty);
  });

  const visibleColumns = columns?.filter((column) => column.isVisible()) || [];

  columnApi.setColumnsVisible(visibleColumns, true);

  props.autoSizeColumns && columnApi.autoSizeAllColumns();
};

export const addHeadersToColDefs = (
  colDefs: ColDef[],
  headers: ComponentDto[],
): ColDef[] => {
  const headersObj = headers.reduce(
    (obj, header) => {
      obj[header.slotName] = header.description;

      return obj;
    },
    {} as Record<string, string>,
  );

  return colDefs.map((colDef) => {
    const headerName = colDef.field && headersObj[colDef.field.split(".")[1]];

    return colDef.field && headerName
      ? { ...colDef, headerName, headerTooltip: headerName }
      : { ...colDef, headerTooltip: colDef.field };
  });
};

export const adjustColumnWidthsBasedOnHeaderNameLength = ({
  gridEvent,
  headerNameLengthThreshold = 25,
  widthForLongHeaderNames = 270,
  widthForShortHeaderNames = 155,
}: {
  gridEvent: FirstDataRenderedEvent;
  headerNameLengthThreshold?: number;
  widthForLongHeaderNames?: number;
  widthForShortHeaderNames?: number;
}) => {
  //DOES NOT WORK IF colDef has minWidth>widthForShortHeaderNames

  const columns = gridEvent.columnApi.getColumns();

  if (!columns) return;

  const updatedColumnWidths = columns.map((column) => {
    const columnDefinition = column.getColDef();
    const headerName =
      columnDefinition.headerName || columnDefinition.field || "";
    const hasLongHeaderName = headerName.length >= headerNameLengthThreshold;

    return {
      key: column.getColId(),
      newWidth: hasLongHeaderName
        ? widthForLongHeaderNames
        : widthForShortHeaderNames,
    };
  });

  gridEvent.columnApi.setColumnWidths(updatedColumnWidths);
};
