import { ColDef, ValueGetterParams } from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import { Spin } from "antd";

export const GRID_MAX_HEIGHT = "326px";
const defaultNumberValueGetter = (params: ValueGetterParams) => {
  const value = params.data?.[params.column.getColId()];

  return typeof value === "number" ? Math.round(value) : value;
};
export const CENTERED_HEADER_CLASS = "centered-columns";
export const CENTERED_CELL_STYLE = { textAlign: "center" };
export const CENTERED_COL_DEF = {
  cellStyle: CENTERED_CELL_STYLE,
  headerClass: CENTERED_HEADER_CLASS,
};

export const COLUMN_TYPES = {
  ACTION_COLUMN: {
    cellStyle: { textAlign: "left" },
  },
  BOOLEAN_COLUMN: {
    cellStyle: {
      ...CENTERED_CELL_STYLE,
      display: "flex",
      justifyContent: "center",
    },
  },
  NUMBER_COLUMN: {
    cellStyle: {
      ...CENTERED_CELL_STYLE,
      display: "flex",
      justifyContent: "center",
    },
    filter: "agNumberColumnFilter",
    valueGetter: defaultNumberValueGetter,
  },
  TEXT_COLUMN: {
    cellStyle: { textAlign: "left" },
    filter: "agTextColumnFilter",
  },
};
export const COLUMN_TYPE_NAMES = {
  ACTION_COLUMN: "ACTION_COLUMN",
  BOOLEAN_COLUMN: "BOOLEAN_COLUMN",
  NUMBER_COLUMN: "NUMBER_COLUMN",
  TEXT_COLUMN: "TEXT_COLUMN",
};

export const GRID_DEFAULT_COL_DEFS: { defaultColDef: ColDef } = {
  defaultColDef: {
    filter: true,
    flex: 1,
    minWidth: 100,
    resizable: true,
    sortable: true,
    wrapHeaderText: true,
    ...CENTERED_COL_DEF,
    tooltipValueGetter(params) {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },
    // type: "NUMBER_COLUMN",
  },
};

type GridProps = { includeDefaultColDefs?: boolean };
export const getGridDefaultProps = ({
  includeDefaultColDefs = true,
}: GridProps = {}): AgGridReactProps => ({
  animateRows: true,
  // rowBuffer: 10,
  columnTypes: COLUMN_TYPES,
  containerStyle: { marginTop: "1rem" },
  loadingOverlayComponent: Spin,
  rowSelection: "multiple",
  stopEditingWhenCellsLoseFocus: true,
  ...(includeDefaultColDefs && GRID_DEFAULT_COL_DEFS),
});
