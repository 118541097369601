import { FC, useCallback, useState } from "react";

import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";

import {
  ClientDto,
  CreateLocationInput,
  LocationByClientIdDto,
  useCreateLocationMutation,
} from "graphql/main";

const CreateLocationModal: FC<{
  onCreateSuccess?: (createdLocationId: LocationByClientIdDto["id"]) => void;
  clientId?: ClientDto["id"];
}> = ({ onCreateSuccess, clientId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateLocationInput>();
  const [createLocation] = useCreateLocationMutation({
    onCompleted: (data) => {
      message.success(t("location_created_successfully"));
      toggleModalOpen();
      onCreateSuccess?.(data.createLocation.id);
    },
  });

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
    form.resetFields();
  };

  const onFormSubmit = useCallback(
    async (values: CreateLocationInput) => {
      await createLocation({
        variables: { input: { ...values, clientId: clientId } },
      });
    },
    [clientId, createLocation],
  );

  return (
    <>
      <Button type="primary" onClick={toggleModalOpen}>
        {t("new_location")}
      </Button>
      <Modal
        title={t("create_location")}
        open={modalOpen}
        onCancel={toggleModalOpen}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            await onFormSubmit(values);
          }}
        >
          <Form.Item
            name="name"
            label={t("location_name")}
            labelCol={{ span: 24 }}
            rules={[{ message: t("field_required"), required: true }]}
          >
            <Input placeholder={t("location_name")} />
          </Form.Item>
          <Form.Item
            name="address"
            label={t("location_address")}
            labelCol={{ span: 24 }}
            rules={[
              {
                message: t("field_required"),
                required: true,
              },
            ]}
          >
            <Input placeholder={t("location_address")} />
          </Form.Item>

          <Form.Item
            name="contractDate"
            label={t("location_contract_date")}
            labelCol={{ span: 24 }}
            rules={[
              {
                message: t("field_required"),
                required: true,
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={t("location_contract_date")}
            />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={t("location_mobile")}
            labelCol={{ span: 24 }}
            rules={[
              {
                message: t("field_required"),
                required: true,
                whitespace: true,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              stringMode
              placeholder={t("location_mobile")}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default CreateLocationModal;
