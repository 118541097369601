import styled from "styled-components";

type StyledPageTitleProps = {
  $marginY?: string;
};

export const StyledPageTitle = styled.h2<StyledPageTitleProps>`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 52px;
  margin: ${({ $marginY }) => $marginY ?? $marginY} 0;
`;
