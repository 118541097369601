import { FC, useEffect, useRef, useState } from "react";

import { GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, DatePicker, Flex, message, Spin, Switch, Select } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import restApiRoutes from "config/restApiRoutes";
import { getGridDefaultProps } from "constants/gridConstants";
import {
  HistoryDto,
  LocationByClientIdDto,
  useGetHistoriesQuery,
  useGetProcessedDataHeaderNamesQuery,
} from "graphql/main";
import useHistoryColDefs from "screens/Private/Client/components/history/useHistoryColDefs";
import { useAuth } from "store/auth/useAuth";
import { adjustColumnWidthsBasedOnHeaderNameLength } from "utils/agGridUtils";
import { downloadBlob } from "utils/blobUtils";

const HistoryTab: FC<{ locationId: LocationByClientIdDto["id"] }> = ({
  locationId,
}) => {
  const gridRef = useRef<AgGridReact<HistoryDto>>(null);

  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<Dayjs>();
  const [toDate, setToDate] = useState<Dayjs>(dayjs);
  const [isRealTime, setIsRealTime] = useState(true);

  const [exportLoading, setExportLoading] = useState(false);

  const { data: headerNames, loading: loadingHeaders } =
    useGetProcessedDataHeaderNamesQuery({
      variables: { locationId },
    });
  const { columnDefs } = useHistoryColDefs(headerNames);
  const { token } = useAuth();
  const [allColumns, setAllColumns] = useState<
    { slotName: string; description: string }[]
  >([]);
  const [selectedColumns, setSelectedColumns] = useState<
    { slotName: string; description: string }[]
  >([]);

  const {
    data: historyData,
    refetch,
    loading: loadingHistories,
  } = useGetHistoriesQuery({
    pollInterval: isRealTime ? 3000 : 0,
    variables: {
      fromDate: isRealTime ? undefined : fromDate,
      locationId,
      toDate: isRealTime ? undefined : toDate,
    },
  });

  const onDateChangeHandler: RangePickerProps["onChange"] = async (dates) => {
    if (dates && dates.length) {
      setFromDate(dates[0]!);
      setToDate(dates[1]!);
      await refetch({ fromDate: dates[0], locationId, toDate: dates[1] });
    }
  };

  useEffect(() => {
    if (!gridRef.current?.api) return;
    !loadingHeaders &&
      setAllColumns(headerNames?.processedDataHeaderNames ?? []);
  }, [loadingHeaders, gridRef, headerNames]);
  useEffect(() => {
    if (!gridRef.current?.api) return;
    loadingHistories
      ? gridRef.current.api.showLoadingOverlay()
      : gridRef.current.api.hideOverlay();
  }, [loadingHistories, gridRef]);
  const onExportHandler = async () => {
    if (!token || !fromDate || !toDate) return;
    setExportLoading(true);

    await fetch(
      restApiRoutes.EXPORT_HISTORIES(
        locationId,
        fromDate!,
        toDate,
        selectedColumns.toString(),
      ),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      },
    )
      .then(async (response) => {
        const blob = await response.blob();
        if (!response.ok) {
          message.error("Something went wrong");
          throw blob;
        }
        downloadBlob(
          blob,
          `export_${fromDate?.format("DD-MM-YYYY-HH-mm")}_${toDate?.format(
            "DD-MM-YYYY-HH-mm",
          )}`,
        );
        setExportLoading(false);
      })
      .catch(() => {
        message.error("Something went wrong");
        setExportLoading(false);
      });
  };

  return (
    <>
      <Flex justify="space-between">
        <div>
          <Select
            mode="multiple"
            disabled={loadingHistories || isRealTime}
            style={{ margin: "0 20px", minWidth: 200 }}
            placeholder={t("select_columns")}
            value={selectedColumns}
            onChange={setSelectedColumns}
            maxTagCount={1}
            options={allColumns.map((item) => ({
              label: item.description,
              value: item.slotName,
            }))}
          />
          <DatePicker.RangePicker
            maxDate={dayjs()}
            disabled={loadingHistories || isRealTime}
            value={[fromDate, toDate]}
            defaultValue={[fromDate, toDate]}
            showTime
            format="DD/MM/YYYY HH:mm"
            onChange={(dates, dateString) =>
              onDateChangeHandler(dates, dateString)
            }
          />
          <Switch
            style={{ margin: "0 20px" }}
            value={isRealTime}
            onChange={(checked) => setIsRealTime(checked)}
          />
          <span>{t("real_time")}</span>
        </div>
        <Button
          type="primary"
          loading={exportLoading}
          onClick={onExportHandler}
          disabled={!fromDate || !toDate}
        >
          {t("export")}
        </Button>
      </Flex>
      <AgGridReact
        getRowId={(params: GetRowIdParams<HistoryDto>) => params.data.id}
        onFirstDataRendered={(gridEvent) =>
          adjustColumnWidthsBasedOnHeaderNameLength({
            gridEvent,
          })
        }
        ref={gridRef}
        loadingOverlayComponent={Spin}
        rowData={historyData?.histories}
        headerHeight={50}
        columnDefs={columnDefs}
        {...getGridDefaultProps()}
      />
    </>
  );
};
export default HistoryTab;
