import { useMemo } from "react";

import { CellClassParams, CellStyle, ColDef } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import { COLUMN_TYPE_NAMES } from "constants/gridConstants";
import { ComponentDto, ComponentFormatEnum, MetricDto } from "graphql/main";

const booleanComponentFormats = [
  ComponentFormatEnum.VirtualOkNokBool,
  ComponentFormatEnum.VirtualOnOffBool,
  ComponentFormatEnum.VirtualYesNoBool,
];
const useMetricsColDefs = (metric?: MetricDto) => {
  const { t } = useTranslation();

  const columnDefs = useMemo<ColDef<ComponentDto>[]>(() => {
    const isBooleanFormat = (format?: ComponentFormatEnum) =>
      format && booleanComponentFormats.includes(format);
    const getBooleanValue = (value?: string | null) => value === "YES";

    const getCellStyle = (params: CellClassParams<ComponentDto>): CellStyle => {
      const isDisabled = !metric?.enableSaveFunction;
      const isValueDifferent =
        params.data?.value !== params.data?.targetValue &&
        params.data?.targetValue !== "---";

      return {
        backgroundColor: isDisabled
          ? "lightgray"
          : isValueDifferent && "#FFB5B5",
        display: "flex",
        justifyContent: "center",
        opacity: isDisabled ? 0.4 : 1,
      } as CellStyle;
    };

    return [
      {
        field: "description",
        flex: 3,
        headerName: t("setting"),
        minWidth: 220,
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        cellRendererSelector: (params) => ({
          component:
            isBooleanFormat(params.data?.componentFormat) &&
            "agCheckboxCellRenderer",
        }),
        field: "value",
        headerName: t("default_value"),
        type: COLUMN_TYPE_NAMES.NUMBER_COLUMN,
        valueGetter: (params) =>
          isBooleanFormat(params.data?.componentFormat)
            ? getBooleanValue(params.data?.value)
            : params.data?.value,
      },
      {
        cellEditorSelector: (params) => ({
          component: isBooleanFormat(params.data?.componentFormat)
            ? "agCheckboxCellEditor"
            : "agNumberCellEditor",
        }),
        cellRendererSelector: (params) => ({
          component:
            isBooleanFormat(params.data?.componentFormat) &&
            "agCheckboxCellRenderer",
        }),
        cellStyle: getCellStyle,
        editable: (params) =>
          (params?.data?.isEditable && metric?.enableSaveFunction) || false,
        field: "targetValue",
        headerName: t("value"),
        hide: !metric?.editingEnabled,
        tooltipValueGetter: (params) =>
          !metric?.enableSaveFunction
            ? t("wait_for_the_process_to_complete")
            : params.data?.targetValue,
        type: COLUMN_TYPE_NAMES.NUMBER_COLUMN,
        valueGetter: (params) =>
          isBooleanFormat(params.data?.componentFormat)
            ? getBooleanValue(params.data?.targetValue)
            : params.data?.targetValue,
      },
    ];
  }, [metric, t]);

  return { columnDefs };
};

export default useMetricsColDefs;
