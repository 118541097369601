import { FC } from "react";

import { GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Col, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  getGridDefaultProps,
  GRID_DEFAULT_COL_DEFS,
} from "constants/gridConstants";
import {
  DistributionBoardComponentDto,
  LocationByClientIdDto,
  useGetPackageBoardQuery,
  useGetProcessedDataHeaderNamesLazyQuery,
  useUpdateDistributionBoardsMutation,
} from "graphql/main";
import usePackageBoardColDefs from "screens/Private/Client/components/communications/usePackageBoardColDefs";

const StyledGrid = styled(AgGridReact)`
  .ag-row {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ag-header-cell-comp-wrapper {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const PackageBoardTab: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { t } = useTranslation();
  const { columnDefs } = usePackageBoardColDefs();
  const { data: boardData, refetch: refetchBoard } = useGetPackageBoardQuery({
    variables: { locationId: locationId },
  });

  const [_, { refetch: refetchProcessedDataHeaders }] =
    useGetProcessedDataHeaderNamesLazyQuery({
      variables: { locationId },
    });

  const [updateDistributionBoard] = useUpdateDistributionBoardsMutation({
    onCompleted: async () => {
      await refetchBoard(locationId);
      await refetchProcessedDataHeaders();

      message.success(t("board_updated_successfully"));
    },
  });

  return (
    <Row style={{ height: "100%", width: "100%" }}>
      <Col xs={24} md={{ offset: 1, span: 17 }} style={{ height: "100%" }}>
        <StyledGrid
          getRowId={(params: GetRowIdParams<DistributionBoardComponentDto>) =>
            params.data.id
          }
          rowData={boardData?.distributionBoard.virtualComponents}
          domLayout="autoHeight"
          rowHeight={22}
          headerHeight={25}
          onCellEditingStopped={async (e) => {
            e.valueChanged &&
              (await updateDistributionBoard({
                variables: {
                  input: {
                    components: [
                      {
                        description: e.newValue,
                        id: e.data.id,
                      },
                    ],
                    id: boardData?.distributionBoard.id,
                  },
                },
              }));
          }}
          defaultColDef={{
            ...GRID_DEFAULT_COL_DEFS.defaultColDef,
            cellStyle: { borderRight: "1px solid #bdc3c7" },
            wrapHeaderText: true,
          }}
          columnDefs={columnDefs}
          {...getGridDefaultProps({ includeDefaultColDefs: true })}
        />
      </Col>
    </Row>
  );
};
export default PackageBoardTab;
