import { FC, useState } from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { cloneDeep } from "@apollo/client/utilities";
import { CellEditingStoppedEvent, GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Col,
  Flex,
  Form,
  InputNumber,
  message,
  Row,
  Switch,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";

import {
  getGridDefaultProps,
  GRID_DEFAULT_COL_DEFS,
} from "constants/gridConstants";
import {
  CategoryEnum,
  ComponentDto,
  LocationByClientIdDto,
  UpdateDistributionBoardSettingsInput,
  useGetMetricsQuery,
  useGetSettingsBoardQuery,
  useUpdateDistributionBoardSettingsMutation,
} from "graphql/main";
import EditValueModal, {
  SettingToUpdateType,
} from "screens/Private/Client/components/metrics/EditValueModal";
import useMetricsColDefs from "screens/Private/Client/components/metrics/useMetricsColDefs";

import MetricsInnerTabs from "./MetricsInnerTabs";

const MetricsTab: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<UpdateDistributionBoardSettingsInput>();
  const [isUpdateSettingModalOpen, setIsUpdateSettingModalOpen] =
    useState(false);
  const [settingToUpdate, setSettingToUpdate] = useState<SettingToUpdateType>();
  const { data: settingsData, refetch: refetchSettings } =
    useGetSettingsBoardQuery({
      onCompleted: async () => {
        form.resetFields();
      },
      pollInterval: 10000,
      variables: { locationId: locationId },
    });

  const { data: metricsData, refetch: refetchMetrics } = useGetMetricsQuery({
    fetchPolicy: "cache-and-network",
    pollInterval:
      (settingsData?.distributionBoardSettings?.sendInterval || 30) * 1000,
    variables: { locationId: locationId },
  });

  const { columnDefs } = useMetricsColDefs(metricsData?.metric);
  const isFastMode = settingsData?.distributionBoardSettings.sendInterval === 2;
  const isPump1Running =
    settingsData?.distributionBoardSettings.emergencyStopPump1;
  const isPump2Running =
    settingsData?.distributionBoardSettings.emergencyStopPump2;
  const isSaveFunctionEnabled = metricsData?.metric.enableSaveFunction;

  const [updateDistributionBoardSettings, { loading: isUpdateSettingLoading }] =
    useUpdateDistributionBoardSettingsMutation({
      onCompleted: async () => {
        await refetchSettings(locationId);
        await refetchMetrics(locationId);
        form.resetFields();
        setSettingToUpdate(undefined);
        setIsUpdateSettingModalOpen(false);
        message.success(t("board_settings_updated_successfully"));
      },
      onError: async (error) => {
        message.error(error.message);
      },
    });

  const toggleFastMode = async () => {
    await updateDistributionBoardSettings({
      variables: {
        input: {
          distributionBoardId: settingsData?.distributionBoardSettings.id,
          sendInterval: isFastMode ? 30 : 2,
        },
      },
    });
  };
  const emergencyStopPump1 = async () => {
    await updateDistributionBoardSettings({
      variables: {
        input: {
          distributionBoardId: settingsData?.distributionBoardSettings.id,
          emergencyStopPump1: !isPump1Running,
        },
      },
    });
  };
  const emergencyStopPump2 = async () => {
    await updateDistributionBoardSettings({
      variables: {
        input: {
          distributionBoardId: settingsData?.distributionBoardSettings.id,
          emergencyStopPump2: !isPump2Running,
        },
      },
    });
  };
  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    const { valueChanged, newValue, data } = event;
    if (!valueChanged || newValue === "" || newValue === null) {
      return;
    }

    const formattedSlotName = data?.slotName
      ? data.slotName.charAt(0).toUpperCase() +
        data.slotName.slice(1).toLowerCase()
      : "";

    const formattedValue =
      typeof newValue === "number"
        ? newValue.toFixed(2)
        : newValue === true
          ? "1.0"
          : "0.0";
    const getValueToDisplay = () => {
      if (typeof newValue === "number") return formattedValue;
      else if (newValue === true) {
        return t("yes");
      } else return t("no");
    };

    setSettingToUpdate({
      description: data?.description,
      slotName: formattedSlotName,
      value: formattedValue,
      valueToDisplay: getValueToDisplay(),
    });

    setIsUpdateSettingModalOpen(true);
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <Flex vertical>
            <h2>{t("metrics_tab")}</h2>
            <MetricsInnerTabs components={metricsData?.metric.components} />
          </Flex>
        </Col>
        <Col style={{ marginTop: "1rem" }} xs={24} md={{ offset: 2, span: 10 }}>
          <Flex align="center" justify={"space-between"}>
            <Flex align="center">
              <Typography.Paragraph
                strong
                style={{ marginBottom: "1rem", marginRight: "0.5rem" }}
              >
                {t("emergency_stop_pumps")}
              </Typography.Paragraph>
              <Typography.Paragraph
                style={{ marginBottom: "1rem", marginRight: "0.5rem" }}
              >
                {t("pump_1")}
              </Typography.Paragraph>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={!isPump1Running}
                onChange={emergencyStopPump1}
                style={{ marginBottom: "1rem", marginRight: "0.5rem" }}
              />
              <Typography.Paragraph>{t("pump_2")}</Typography.Paragraph>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={!isPump2Running}
                onChange={emergencyStopPump2}
                style={{ marginBottom: "1rem", marginLeft: "0.5rem" }}
              />
            </Flex>
            <Flex
              align="center"
              style={{ marginLeft: "1rem" }}
              justify="space-between"
            >
              <Typography.Paragraph strong>
                {t("fast_mode")}
              </Typography.Paragraph>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isFastMode}
                onChange={toggleFastMode}
                style={{ marginBottom: "1rem", marginLeft: "0.5rem" }}
              />
            </Flex>
          </Flex>

          <Form
            form={form}
            disabled={isUpdateSettingLoading || isFastMode}
            initialValues={settingsData?.distributionBoardSettings}
            onFinish={async (values: UpdateDistributionBoardSettingsInput) => {
              values.sendInterval
                ? await updateDistributionBoardSettings({
                    variables: {
                      input: {
                        ...values,
                        distributionBoardId:
                          settingsData?.distributionBoardSettings.id,
                      },
                    },
                  })
                : message.warning(t("number_must_be_positive"));
            }}
          >
            <Flex justify={"space-between"} align={"baseline"}>
              <Flex justify={"start"} align={"baseline"}>
                <span>{t("scan_time")}</span>
                <Form.Item name="sendInterval">
                  <InputNumber
                    style={{ marginLeft: "1rem", width: "50%" }}
                    min={0}
                    step={1}
                  />
                </Form.Item>
              </Flex>
              <Button
                style={{ width: "20%" }}
                disabled={isUpdateSettingLoading || isFastMode}
                type="primary"
                htmlType="submit"
              >
                {t("save")}
              </Button>
            </Flex>
          </Form>
          <AgGridReact
            getRowId={(params: GetRowIdParams<ComponentDto>) =>
              params.data.slotName
            }
            onRowDataUpdated={(event) =>
              event.api.refreshCells({ force: true })
            }
            onCellEditingStopped={onCellEditingStopped}
            rowData={cloneDeep(metricsData?.metric.components)?.filter(
              (component) => component.category === CategoryEnum.Setting,
            )}
            domLayout="autoHeight"
            defaultColDef={{
              ...GRID_DEFAULT_COL_DEFS.defaultColDef,
              autoHeaderHeight: true,
            }}
            columnDefs={columnDefs}
            {...getGridDefaultProps({ includeDefaultColDefs: false })}
          />
        </Col>
      </Row>
      <EditValueModal
        isUpdateSettingModalOpen={isUpdateSettingModalOpen}
        boardId={settingsData?.distributionBoardSettings.id}
        settingToUpdate={settingToUpdate}
        saveEnabled={isSaveFunctionEnabled}
        onCancel={() => {
          setSettingToUpdate(undefined);
          setIsUpdateSettingModalOpen(false);
        }}
        onOk={async (input) =>
          await updateDistributionBoardSettings({
            variables: {
              input,
            },
          })
        }
      />
    </>
  );
};
export default MetricsTab;
