import { FC, useState } from "react";

import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Flex,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import AlarmLogDropdown from "components/dropdowns/AlarmLogDropdown";
import CreateLocationModal from "components/modals/CreateLocationModal";
import {
  LocationByClientIdDto,
  LocationDto,
  useDeleteLocationMutation,
  useGetClientQuery,
  useGetLocationByClientIdQuery,
} from "graphql/main";

import ClientTabs from "./components/ClientTabs";

const StyledRow = styled(Row)`
  width: 100%;
  padding-top: 1rem;
`;
const StyledAvatar = styled(Avatar)`
  background-color: darkgray;
  margin-right: 1rem;
`;
const StyledSpan = styled.span`
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
`;
export const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    .anticon-delete {
      display: none;
    }
  }
`;

const Client: FC = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const location = useLocation();
  const state = location.state as { location?: LocationDto["id"] } | null;
  const [isDeleteLocationModalOpen, setIsDeleteLocationModalOpen] =
    useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<
    LocationByClientIdDto["id"] | LocationDto["id"]
  >(state?.location);
  const {
    data: allLocations,
    loading,
    refetch: refetchLocations,
  } = useGetLocationByClientIdQuery({
    onCompleted: (data) => {
      !state?.location &&
        !selectedLocationId &&
        setSelectedLocationId(data.locationsByClientId[0].id);
    },
    variables: {
      clientId: clientId,
    },
  });
  const { data: clientData } = useGetClientQuery({
    variables: {
      id: clientId,
    },
  });

  const [deleteLocation, { loading: isDeleteLocationLoading }] =
    useDeleteLocationMutation({
      onCompleted: () => {
        setIsDeleteLocationModalOpen(false);
        allLocations?.locationsByClientId.length &&
          setSelectedLocationId(allLocations?.locationsByClientId[0].id);
        refetchLocations();
        message.success(t("location_deleted_successfully"));
      },
    });

  return (
    <>
      <StyledRow>
        <Col span={16}>
          <Space direction="vertical">
            <Flex>
              <Space
                style={{
                  backgroundColor: "#E2E2E2",
                  borderRadius: 13,
                  padding: "1rem",
                }}
              >
                <StyledAvatar
                  size="large"
                  shape="square"
                  icon={<UserOutlined />}
                />
                <StyledSpan>{clientData?.client.name}</StyledSpan>
                {!loading ? (
                  <Flex style={{ marginLeft: "1rem" }} align="center">
                    <label htmlFor="locations">{t("locations")}:</label>
                    <StyledSelect
                      id="locations"
                      style={{
                        borderRadius: 13,
                        marginLeft: "0.5rem",
                        minWidth: "20rem",
                      }}
                      options={allLocations?.locationsByClientId.map(
                        (location) => ({
                          label: (
                            <Flex justify="space-between">
                              <span>{location.name}</span>
                              <DeleteOutlined
                                spin={isDeleteLocationModalOpen}
                                title={t("delete")}
                                onClick={() =>
                                  setIsDeleteLocationModalOpen(true)
                                }
                              />
                            </Flex>
                          ),
                          title: location.name,
                          value: location.id,
                        }),
                      )}
                      onSelect={(value) => {
                        value && setSelectedLocationId(value);
                      }}
                      placeholder={t("locations")}
                      filterOption
                      optionFilterProp="label"
                      value={selectedLocationId}
                      allowClear={false}
                      showSearch
                    />
                  </Flex>
                ) : (
                  <Spin />
                )}
                <CreateLocationModal
                  onCreateSuccess={async (createdLocationId) => {
                    setSelectedLocationId(createdLocationId);
                    await refetchLocations();
                  }}
                  clientId={clientId}
                />
              </Space>
            </Flex>
          </Space>
        </Col>
        <Col xs={{ offset: 7, span: 1 }}>
          {selectedLocationId && (
            <AlarmLogDropdown locationId={selectedLocationId} />
          )}
        </Col>
      </StyledRow>
      {selectedLocationId && <ClientTabs locationId={selectedLocationId} />}
      <Modal
        title={t("delete_warning_location")}
        centered
        open={isDeleteLocationModalOpen}
        confirmLoading={isDeleteLocationLoading}
        okText={t("delete")}
        okType="danger"
        onOk={() =>
          deleteLocation({
            variables: {
              input: selectedLocationId,
            },
          })
        }
        onCancel={() => setIsDeleteLocationModalOpen(false)}
      />
    </>
  );
};
export default Client;
