import { FC, useCallback, useState } from "react";

import { Button, Col, DatePicker, Form, Input, message, Row } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  LocationByClientIdDto,
  UpdateLocationInput,
  useGetLocationQuery,
  useUpdateLocationMutation,
} from "graphql/main";

const GeneralTab: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [form] = Form.useForm<UpdateLocationInput>();
  const {
    data: locationData,
    loading,
    refetch,
  } = useGetLocationQuery({
    onCompleted: () => {
      form.resetFields();
    },
    variables: {
      locationId: locationId,
    },
  });
  const [updateLocation, { loading: updateLoading }] =
    useUpdateLocationMutation({
      onCompleted: async () => {
        await refetch(locationId);
        message.success(t("location_updated_successfully"));
        setIsDisabled(true);
      },
    });

  const onFormSubmit = useCallback(
    async (values: UpdateLocationInput) => {
      await updateLocation({
        variables: {
          input: {
            ...values,
            contractDate: dayjs(values.contractDate).toDate(),
            id: locationId,
          },
        },
      });
    },
    [locationId, updateLocation],
  );

  const REQUIRED_RULES = [{ message: t("field_required"), required: true }];

  return (
    <Row style={{ width: "100%" }}>
      <Col xs={24} md={8}>
        {locationData?.location && (
          <Form
            onChange={() => setIsDisabled(false)}
            form={form}
            initialValues={{
              ...locationData.location,
              contractDate: dayjs(locationData.location.contractDate),
            }}
            onFinish={onFormSubmit}
          >
            <Form.Item rules={REQUIRED_RULES} name="name" label={t("name")}>
              <Input placeholder={t("location_name")} />
            </Form.Item>
            <Form.Item
              rules={REQUIRED_RULES}
              name="address"
              label={t("address")}
            >
              <Input placeholder={t("location_address")} />
            </Form.Item>
            <Form.Item
              rules={REQUIRED_RULES}
              name="contractDate"
              label={t("contract_date")}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("location_contract_date")}
                onChange={() => {
                  setIsDisabled(false);
                }}
              />
            </Form.Item>
            <Form.Item rules={REQUIRED_RULES} name="mobile" label={t("mobile")}>
              <Input placeholder={t("location_mobile")} />
            </Form.Item>
            <Button
              disabled={updateLoading || loading || isDisabled}
              type="primary"
              style={{ width: "100%" }}
              htmlType="submit"
            >
              {t("save")}
            </Button>
          </Form>
        )}
      </Col>
    </Row>
  );
};
export default GeneralTab;
