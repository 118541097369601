import { FC, ReactNode } from "react";

import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import AllRoutes, { ClientTabsType } from "screens/AllRoutes";
import AlarmsInnerTabs from "screens/Private/Client/components/alarms/AlarmsInnerTabs";
import PackageBoardTab from "screens/Private/Client/components/communications/PackageBoardTab";
import AlarmsConfigurationTab from "screens/Private/Client/components/configuration/AlarmsConfigurationTab";
import DistributionBoardTab from "screens/Private/Client/components/distributionBoard/DistributionBoardTab";
import HistoryTab from "screens/Private/Client/components/history/HistoryTab";
import MetricsTab from "screens/Private/Client/components/metrics/MetricsTab";

import GeneralTab from "./GeneralTab";

export const StyledTabs = styled(Tabs)`
  margin-top: 2rem;
  height: 100%;

  .ant-tabs-content-holder {
    height: 95%;
  }

  .ant-tabs-content {
    height: 95%;
    .ant-tabs-content-top {
      height: 95%;
    }
  }
  .ant-tabs-tabpane {
    height: 95%;
    .ant-tabs-tabpane-active {
      height: 95%;
    }
  }
`;
const ClientTabs: FC<{ locationId: string }> = ({ locationId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { clientId, selectedTab } = useParams<{
    clientId: string;
    selectedTab?: string;
  }>();

  const items: {
    children: ReactNode;
    key: ClientTabsType;
    label: ReactNode;
  }[] = [
    {
      children: <GeneralTab locationId={locationId} />,
      key: "general",
      label: t("general_tab"),
    },
    {
      children: <DistributionBoardTab locationId={locationId} />,
      key: "tech-diagram",
      label: t("tech_diagram_tab"),
    },
    {
      children: <HistoryTab locationId={locationId} />,
      key: "history",
      label: t("history_tab"),
    },
    {
      children: <MetricsTab locationId={locationId} />,
      key: "metrics",
      label: t("metrics_tab"),
    },
    {
      children: <PackageBoardTab locationId={locationId} />,
      key: "communications",
      label: t("packages_tab"),
    },
    {
      children: <AlarmsConfigurationTab locationId={locationId} />,
      key: "configuration",
      label: t("alarms_configuration_tab"),
    },
    {
      children: <AlarmsInnerTabs locationId={locationId} />,
      key: "alarms",
      label: t("alarms_tab"),
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey={selectedTab}
      items={items}
      activeKey={selectedTab}
      onChange={(tabKey) =>
        clientId &&
        navigate(
          AllRoutes.Client.DynamicPathWithTab(
            clientId,
            tabKey as ClientTabsType,
          ),
        )
      }
    />
  );
};
export default ClientTabs;
