import { FC, useCallback, useMemo, useRef, useState } from "react";

import { DeleteTwoTone } from "@ant-design/icons";
import { ColDef, GetRowIdParams, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Flex, message, Modal, Space } from "antd";
import Search from "antd/lib/input/Search";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CreateClientModal from "components/modals/CreateClientModal";
import { dateTimeFormatter } from "components/UI/agGrid/valueFormatters";
import {
  COLUMN_TYPE_NAMES,
  getGridDefaultProps,
} from "constants/gridConstants";
import {
  ClientDto,
  useDeleteClientMutation,
  useGetClientsQuery,
} from "graphql/main";
import AllRoutes from "screens/AllRoutes";

const ClientsGrid: FC = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [clientIdForDeletion, setClientIdForDeletion] =
    useState<ClientDto["id"]>();

  const { data: clientsData, refetch } = useGetClientsQuery();
  const onSearch = useCallback(
    async (value: string) => await refetch({ searchText: value }),
    [refetch],
  );
  const [deleteClient, { loading: isDeleteClientLoading }] =
    useDeleteClientMutation({
      onCompleted: async () => {
        setIsDeleteClientModalOpen(false);
        await refetch();
        message.success(t("client_deleted_successfully"));
        setClientIdForDeletion(undefined);
      },
    });
  const columnDefs = useMemo<ColDef<ClientDto>[]>(
    () => [
      {
        field: "name",
        headerName: t("name"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "dateCreated",
        headerName: t("dateCreated"),
        valueFormatter: dateTimeFormatter,
      },
      {
        cellRenderer: (params: ICellRendererParams) => (
          <DeleteTwoTone
            style={{ fontSize: "150%" }}
            title={t("delete")}
            onClick={() => {
              setClientIdForDeletion(params.data.id);
              setIsDeleteClientModalOpen(true);
            }}
          />
        ),
        filter: false,
        headerName: t("actions"),
        sortable: false,
      },
    ],
    [t],
  );

  return (
    <>
      <h1>{t("clients")}</h1>
      <Flex justify="space-between">
        <Space size="large">
          <Search
            onSearch={onSearch}
            onChange={(e) => onSearch(e.target.value)}
            style={{ width: "15rem" }}
            placeholder={t("search")}
          />
          <CreateClientModal onCreateSuccess={refetch} />
        </Space>
      </Flex>
      <div style={{ height: "85%", width: "40%" }}>
        <AgGridReact
          ref={gridRef}
          getRowId={(params: GetRowIdParams<ClientDto>) => params.data.id}
          rowData={clientsData?.clients}
          columnDefs={columnDefs}
          onRowDoubleClicked={(event) =>
            navigate(AllRoutes.Client.DynamicPath(event.data?.id))
          }
          {...getGridDefaultProps()}
        />
      </div>
      <Modal
        title={t("delete_warning_client")}
        centered
        open={isDeleteClientModalOpen}
        confirmLoading={isDeleteClientLoading}
        okText={t("delete")}
        okType="danger"
        onOk={() =>
          clientIdForDeletion &&
          deleteClient({
            variables: {
              input: clientIdForDeletion,
            },
          })
        }
        onCancel={() => {
          setClientIdForDeletion(undefined);
          setIsDeleteClientModalOpen(false);
        }}
      />
    </>
  );
};

export default ClientsGrid;
