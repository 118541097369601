import { useMemo } from "react";

import { ApolloQueryResult } from "@apollo/client";
import { ColDef } from "ag-grid-community";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import {
  AlarmConfigurationDto,
  GetAlarmConfigurationsQuery,
  NotificationTypeEnum,
  useUpdateAlarmConfigurationMutation,
} from "graphql/main";

const useAlarmsConfigurationColDefs = (
  refetchAlarms?: () => Promise<ApolloQueryResult<GetAlarmConfigurationsQuery>>,
) => {
  const [t] = useTranslation();
  const [updateAlarm] = useUpdateAlarmConfigurationMutation({
    onCompleted: () => {
      message.success(t("alarm_configuration_updated_successfully"));
      refetchAlarms?.();
    },
  });
  const localizedNotificationTypes = useMemo(
    () => ({
      [NotificationTypeEnum.Email]: t("email"),
      [NotificationTypeEnum.None]: t("none"),
      [NotificationTypeEnum.Sms]: t("sms"),
    }),
    [t],
  );

  const columnDefs = useMemo<ColDef<AlarmConfigurationDto>[]>(
    () => [
      {
        field: "code",
        headerName: t("code"),
        maxWidth: 100,
      },
      {
        field: "description",
        headerName: t("description"),
      },
      {
        field: "alarmCondition",
        headerName: t("alarmCondition"),
        maxWidth: 150,
      },
      {
        field: "alarmValue",
        headerName: t("alarmValue"),
        maxWidth: 150,
      },
      {
        field: "alarmMinutesPeriod",
        headerName: t("alarmMinutesPeriod"),
        maxWidth: 150,
      },
      {
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: Object.values(NotificationTypeEnum),
        },
        editable: true,
        field: "notificationType",
        filter: false,
        headerName: t("notification_type"),
        maxWidth: 150,
        onCellValueChanged: async (params) => {
          await updateAlarm({
            variables: {
              input: { id: params.data.id, notificationType: params.newValue },
            },
          });
        },
        valueFormatter: (params) =>
          localizedNotificationTypes[
            params.value as keyof typeof localizedNotificationTypes
          ] || params.value,
      },
    ],
    [localizedNotificationTypes, t, updateAlarm],
  );

  return { columnDefs };
};

export default useAlarmsConfigurationColDefs;
