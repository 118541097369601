import { FC } from "react";

import { DownOutlined, GlobalOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, MenuProps, Space } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

import logo from "assets/images/hydrokraft_logo.svg";
import AllRoutes from "screens/AllRoutes";
import { useAuth } from "store/auth/useAuth";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  height: 6rem;
  align-items: center;
  padding: 0.75rem 2rem;
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
`;
const StyledLogo = styled.img`
  width: auto;
  cursor: pointer;
  height: 80%;
  margin-right: 4rem;
`;
const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const StyledNavLink = styled(NavLink)`
  margin-right: 2rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: #69b1ff;
  &:hover {
    color: #9be3ff;
  }
`;
const LogoutButton = styled(Button)`
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: bold;
  background: lightgray;
  color: rgba(0, 0, 0, 0.88);

  &:hover {
    color: #9be3ff;
  }
`;
const NavItems: FC = () => {
  const { t } = useTranslation();
  const navLinks = [
    { label: t("locations_grid"), to: AllRoutes.LocationsGrid },
    { label: t("locations_dashboard"), to: AllRoutes.DashboardLocations },
    { label: t("clients"), to: AllRoutes.Clients },
    { label: t("users"), to: AllRoutes.Users },
  ];

  return (
    <div style={{ display: "flex" }}>
      {navLinks.map((link, i) => (
        <StyledNavLink key={i} to={link.to}>
          {link.label}
        </StyledNavLink>
      ))}
    </div>
  );
};
const LayoutHeader: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const allLanguages = Object.keys(i18n.services.resourceStore.data);
  const currentLanguage = i18n.resolvedLanguage || "en";

  const items: MenuProps["items"] = allLanguages.map((lang) => ({
    disabled: lang === currentLanguage,
    key: lang,
    label: t(lang),
    onClick: () => i18n.changeLanguage(lang),
  }));

  return (
    <StyledHeader>
      <StyledLogo onClick={() => navigate(AllRoutes.Dashboard)} src={logo} />
      <LinksContainer>
        <NavItems />

        <Space size="large">
          <Dropdown menu={{ items }}>
            <Space style={{ color: "#69b1ff", fontSize: "0.875rem" }}>
              <GlobalOutlined />
              <span>{t(currentLanguage)}</span>
              <DownOutlined />
            </Space>
          </Dropdown>
          <LogoutButton
            onClick={async () => {
              logout();
              navigate(AllRoutes.Login);
            }}
          >
            {t("logout")}
          </LogoutButton>
        </Space>
      </LinksContainer>
    </StyledHeader>
  );
};

export default LayoutHeader;
