import { FC } from "react";

import { Flex, Tabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { CategoryEnum, ComponentDto } from "graphql/main";

type StyledSpanProps = {
  $bold?: boolean;
};
export const StyledValueContainer = styled(Flex)`
  border-bottom: 1px black solid;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
`;
export const StyledValueSpan = styled.span<StyledSpanProps>`
  margin-top: 0.75rem;
  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: bold;
    `}
`;
const MetricsInnerTabs: FC<{
  components?: ComponentDto[] | null;
}> = ({ components }) => {
  const { t } = useTranslation();

  const mapComponents = (categoryEnum: CategoryEnum) => {
    const filteredComponents = components?.filter(
      (component) => component.category === categoryEnum,
    );

    return filteredComponents
      ? filteredComponents.map((component) => (
          <StyledValueContainer key={component.slotName}>
            <StyledValueSpan style={{ marginRight: "5rem" }} $bold={true}>
              {component.description}
            </StyledValueSpan>
            <StyledValueSpan>{component.value || "-"}</StyledValueSpan>
          </StyledValueContainer>
        ))
      : t("metrics_not_found");
  };

  const items: TabsProps["items"] = [
    {
      children: <Flex vertical>{mapComponents(CategoryEnum.Global)}</Flex>,
      key: "global",
      label: t("global"),
    },
    {
      children: <Flex vertical>{mapComponents(CategoryEnum.Pump1)}</Flex>,
      key: "pump-1",
      label: t("pump_1"),
    },
    {
      children: <Flex vertical>{mapComponents(CategoryEnum.Pump2)}</Flex>,
      key: "pump-2",
      label: t("pump_2"),
    },
    {
      children: <Flex vertical>{mapComponents(CategoryEnum.Statistic)}</Flex>,
      key: "statistics",
      label: t("statistics"),
    },
  ];

  return (
    <Tabs
      destroyInactiveTabPane
      defaultActiveKey="1"
      tabPosition="left"
      items={items}
    />
  );
};
export default MetricsInnerTabs;
