import { FC, useCallback, useEffect, useRef } from "react";

import { GetRowIdParams, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useLocation } from "react-router-dom";

import {
  getGridDefaultProps,
  GRID_DEFAULT_COL_DEFS,
} from "constants/gridConstants";
import {
  AlarmDto,
  LocationByClientIdDto,
  useGetAlarmsQuery,
  useUpdateAlarmMutation,
} from "graphql/main";
import useAlarmsColDefs from "screens/Private/Client/components/alarms/useAlarmsColDefs";

const AlarmsTab: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { data: alarmsData, refetch: refetchAlarms } = useGetAlarmsQuery({
    pollInterval: 10000,
    variables: { locationId: locationId },
  });
  const gridRef = useRef<AgGridReact<AlarmDto>>(null);

  const location = useLocation();
  const state = location.state as { selectedAlarmId?: AlarmDto["id"] } | null;

  const { columnDefs } = useAlarmsColDefs(refetchAlarms);
  const [updateAlarm] = useUpdateAlarmMutation();

  const flashSelectedAlarmRow = useCallback(() => {
    const gridApi = gridRef?.current?.api;
    if (!gridApi) return;
    let selectedAlarmRowIndex: IRowNode<AlarmDto>["rowIndex"] = null;

    gridApi.forEachNode((node) => {
      if (node.data?.id === state?.selectedAlarmId) {
        selectedAlarmRowIndex = node.rowIndex;

        return false; //break loop when index found
      }
    });

    if (selectedAlarmRowIndex !== null) {
      const rowNode = gridApi.getDisplayedRowAtIndex(selectedAlarmRowIndex);
      rowNode && gridApi.flashCells({ rowNodes: [rowNode] });
    }
  }, [state, gridRef]);

  useEffect(() => {
    flashSelectedAlarmRow();
  }, [flashSelectedAlarmRow, state]);

  return (
    <AgGridReact
      ref={gridRef}
      getRowId={(params: GetRowIdParams<AlarmDto>) => params.data.id}
      rowData={alarmsData?.alarms}
      headerHeight={32}
      rowHeight={37}
      cellFlashDelay={2000}
      onFirstDataRendered={flashSelectedAlarmRow}
      getRowStyle={(params) =>
        params.data?.isAcknowledged
          ? { fontWeight: "normal" }
          : { fontWeight: "bold" }
      }
      defaultColDef={{
        ...GRID_DEFAULT_COL_DEFS.defaultColDef,
        enableCellChangeFlash: true,
        minWidth: 140,
        wrapHeaderText: true,
      }}
      onRowDoubleClicked={async (event) => {
        if (!event.data?.isAcknowledged) {
          await updateAlarm({
            variables: { input: { id: event.data?.id, isAcknowledged: true } },
          });
          await refetchAlarms();
        }
      }}
      columnDefs={columnDefs}
      {...getGridDefaultProps({ includeDefaultColDefs: false })}
    />
  );
};
export default AlarmsTab;
