import { useMemo } from "react";

import { ColDef } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import { COLUMN_TYPE_NAMES } from "constants/gridConstants";
import { DistributionBoardComponentDto } from "graphql/main";

const useDistributionBoardColDefs = () => {
  const { t } = useTranslation();

  const columnDefs = useMemo<ColDef<DistributionBoardComponentDto>[]>(
    () => [
      {
        field: "connectorCode",
        headerName: t("connector"),
        maxWidth: 120,
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "channelCode",
        headerName: t("channel"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "fuseSpec",
        headerName: t("fuse"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "name",
        headerName: t("device"),
        maxWidth: 220,
        minWidth: 220,
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        cellStyle: { borderRight: "none" },
        editable: true,
        field: "description",
        headerName: t("description"),
        minWidth: 300,
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
        valueSetter: (params) => {
          return params.oldValue !== params.newValue;
        },
      },
    ],
    [t],
  );
  const columnDefsWithHeaders = useMemo<
    ColDef<DistributionBoardComponentDto>[]
  >(() => {
    return columnDefs;
  }, [columnDefs]);

  return { columnDefs: columnDefsWithHeaders };
};

export default useDistributionBoardColDefs;
