import { FC, useCallback, useMemo, useRef, useState } from "react";

import {
  DeleteTwoTone,
  LockOutlined,
  MailTwoTone,
  UnlockTwoTone,
} from "@ant-design/icons";
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Flex, message, Modal, Space } from "antd";
import Search from "antd/lib/input/Search";
import { useTranslation } from "react-i18next";

import CreateUserModal from "components/modals/CreateUserModal";
import { userRoleEnumValueFormatter } from "components/UI/agGrid/valueFormatters";
import {
  COLUMN_TYPE_NAMES,
  getGridDefaultProps,
} from "constants/gridConstants";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
  useInviteUserMutation,
  UserDto,
  useToggleUserActivationMutation,
} from "graphql/main";

const UsersGrid: FC = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isSendInviteModalOpen, setIsSendInviteUserModalOpen] = useState(false);
  const [isToggleActivationModalOpen, setIsToggleActivationModalOpen] =
    useState(false);
  const [userDataForAction, setUserDataForAction] = useState<{
    id?: UserDto["id"];
    isActive?: UserDto["isActive"];
  }>();

  const { data: usersData, refetch } = useGetUsersQuery();
  const onSearch = useCallback(
    async (value: string) => await refetch({ searchText: value }),
    [refetch],
  );
  const [deleteUser, { loading: isDeleteUserLoading }] = useDeleteUserMutation({
    onCompleted: async () => {
      setIsDeleteUserModalOpen(false);
      await refetch();
      message.success(t("client_deleted_successfully"));
      setUserDataForAction(undefined);
    },
  });
  const [inviteUser, { loading: invitationSendLoading }] =
    useInviteUserMutation({
      onCompleted: async () => {
        setIsSendInviteUserModalOpen(false);
        await refetch();
        message.success(t("user_invitation_email_send"));
        setUserDataForAction(undefined);
      },
    });
  const [deactivateUser, { loading: activationToggleLoading }] =
    useToggleUserActivationMutation({
      onCompleted: async (data) => {
        setIsToggleActivationModalOpen(false);
        await refetch();
        message.success(
          data.deactivateUser.isActive
            ? t("user_activated")
            : t("user_deactivated"),
        );
        setUserDataForAction(undefined);
      },
    });
  const columnDefs = useMemo<ColDef<UserDto>[]>(
    () => [
      {
        field: "name",
        headerName: t("name"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "email",
        headerName: t("email"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "role",
        headerName: t("role"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
        valueFormatter: (params: ValueFormatterParams) =>
          userRoleEnumValueFormatter(params, t),
      },
      {
        field: "clientName",
        headerName: t("client_name"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        cellRenderer: (params: ICellRendererParams) => (
          <Flex justify="center">
            <DeleteTwoTone
              style={{ fontSize: "150%" }}
              title={t("delete")}
              onClick={() => {
                setUserDataForAction({ id: params.data.id });
                setIsDeleteUserModalOpen(true);
              }}
            />
            <MailTwoTone
              style={{ fontSize: "150%", marginLeft: "1rem" }}
              title={t("invite")}
              onClick={() => {
                setUserDataForAction({ id: params.data.id });
                setIsSendInviteUserModalOpen(true);
              }}
            />
            {params.data.isActive ? (
              <LockOutlined
                style={{
                  fontSize: "150%",
                  marginLeft: "1rem",
                }}
                title={t("deactivate")}
                onClick={() => {
                  setUserDataForAction({
                    id: params.data.id,
                    isActive: false,
                  });
                  setIsToggleActivationModalOpen(true);
                }}
              />
            ) : (
              <UnlockTwoTone
                style={{
                  fontSize: "150%",
                  marginLeft: "1rem",
                  opacity: "100% !important",
                }}
                title={t("activate")}
                onClick={() => {
                  setUserDataForAction({
                    id: params.data.id,
                    isActive: true,
                  });
                  setIsToggleActivationModalOpen(true);
                }}
              />
            )}
          </Flex>
        ),
        filter: false,
        headerName: t("actions"),
        sortable: false,
      },
    ],
    [t],
  );

  return (
    <>
      <h1>{t("users")}</h1>
      <Flex justify="space-between">
        <Space size="large">
          <Search
            onSearch={onSearch}
            onChange={(e) => onSearch(e.target.value)}
            style={{ width: "15rem" }}
            placeholder={t("search")}
          />
          <CreateUserModal onCreateSuccess={refetch} />
        </Space>
      </Flex>
      <div style={{ height: "85%", width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          getRowId={(params: GetRowIdParams<UserDto>) => params.data.id}
          rowData={usersData?.users}
          columnDefs={columnDefs}
          getRowStyle={(params) => ({
            background: !params.data.isActive ? "#EEEEEE" : "unset",
          })}
          {...getGridDefaultProps()}
        />
      </div>
      <Modal
        title={t("delete_warning_user")}
        centered
        open={isDeleteUserModalOpen}
        confirmLoading={isDeleteUserLoading}
        okText={t("delete")}
        cancelText={t("cancel")}
        okType="danger"
        onOk={() =>
          userDataForAction?.id &&
          deleteUser({
            variables: {
              input: userDataForAction.id,
            },
          })
        }
        onCancel={() => {
          setUserDataForAction(undefined);
          setIsDeleteUserModalOpen(false);
        }}
      />
      <Modal
        title={t("invite_warning_user")}
        centered
        open={isSendInviteModalOpen}
        confirmLoading={invitationSendLoading}
        okText={t("yes")}
        cancelText={t("cancel")}
        okType="primary"
        onOk={() =>
          userDataForAction?.id &&
          inviteUser({
            variables: {
              input: userDataForAction?.id,
            },
          })
        }
        onCancel={() => {
          setUserDataForAction(undefined);
          setIsSendInviteUserModalOpen(false);
        }}
      />
      <Modal
        title={
          userDataForAction?.isActive
            ? t("activate_warning_user")
            : t("deactivate_warning_user")
        }
        centered
        open={isToggleActivationModalOpen}
        confirmLoading={activationToggleLoading}
        okText={t("yes")}
        closable={false}
        cancelText={t("cancel")}
        okType="primary"
        onOk={() =>
          userDataForAction?.id &&
          userDataForAction.isActive !== undefined &&
          deactivateUser({
            variables: {
              input: userDataForAction?.id,
              isActive: userDataForAction.isActive,
            },
          })
        }
        onCancel={() => {
          setUserDataForAction(undefined);
          setIsToggleActivationModalOpen(false);
        }}
      />
    </>
  );
};

export default UsersGrid;
