import { FC, useCallback, useState } from "react";

import { Button, Form, Input, message, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";

import {
  CreateUserInput,
  useCreateUserMutation,
  useGetClientsQuery,
  UserRoleEnum,
} from "graphql/main";

const CreateUserModal: FC<{
  onCreateSuccess?: () => void;
}> = ({ onCreateSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateUserInput>();
  const { data: clientsData } = useGetClientsQuery();
  const selectedRole = Form.useWatch("role", form);

  const [createUser] = useCreateUserMutation({
    onCompleted: () => {
      message.success(t("user_created_successfully"));
      toggleModalOpen();
      onCreateSuccess?.();
    },
  });

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
    form.resetFields();
  };

  const onFormSubmit = useCallback(
    async (values: CreateUserInput) => {
      await createUser({
        variables: { input: { ...values } },
      });
    },
    [createUser],
  );

  return (
    <>
      <Button type="primary" onClick={toggleModalOpen}>
        {t("new_user")}
      </Button>
      <Modal
        title={t("create_user")}
        open={modalOpen}
        onCancel={toggleModalOpen}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            await onFormSubmit(values);
          }}
        >
          <Form.Item
            name="name"
            label={t("username")}
            labelCol={{ span: 24 }}
            rules={[
              {
                message: t("field_required"),
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input placeholder={t("username")} />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("email")}
            labelCol={{ span: 24 }}
            rules={[
              {
                message: t("field_required"),
                required: true,
                type: "email",
              },
            ]}
          >
            <Input type="email" placeholder={t("email")} />
          </Form.Item>
          <Form.Item
            name="password"
            label={t("password")}
            labelCol={{ span: 24 }}
            rules={[
              {
                message: t("field_required"),
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input type="password" placeholder={t("password")} />
          </Form.Item>
          <Form.Item
            name="role"
            label={t("role")}
            labelCol={{ span: 24 }}
            rules={[
              {
                message: t("field_required"),
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Select placeholder={t("select_role")}>
              <Select.Option value={UserRoleEnum.Client}>
                {t("client")}
              </Select.Option>
              <Select.Option value={UserRoleEnum.Admin}>
                {t("admin")}
              </Select.Option>
            </Select>
          </Form.Item>
          {selectedRole === UserRoleEnum.Client && (
            <Form.Item
              name="clientId"
              label={t("client")}
              labelCol={{ span: 24 }}
              rules={[{ message: t("field_required"), required: true }]}
            >
              <Select placeholder={t("select_client")}>
                {clientsData?.clients.map((client) => (
                  <Select.Option key={client.id} value={client.id}>
                    {client.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
export default CreateUserModal;
