import { FC, useCallback } from "react";

import { BellOutlined } from "@ant-design/icons";
import { Col, Divider, Dropdown, Flex, MenuProps, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  AlarmDescriptionDto,
  AlarmDto,
  LocationDto,
  useGetAlarmsQuery,
} from "graphql/main";
import AllRoutes from "screens/AllRoutes";
import { getFormattedDate } from "utils/dateUtils";
const StyledBellIcon = styled(BellOutlined)`
  font-size: 2rem;
  cursor: pointer;
`;
const StyledAlarmsLengthBadge = styled.span`
  position: absolute;
  border-radius: 50%;
  right: 0;
  text-align: center;
  background-color: #eba7a7;
  font-size: 0.75rem;
  line-height: 15px;
  width: 15px;
  pointer-events: none;
  height: 15px;
`;
const NUMBER_OF_ALARMS_TO_SHOW = 10;
const AlarmLogDropdown: FC<{ locationId: LocationDto["id"] }> = ({
  locationId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetAlarmsQuery({
    pollInterval: 60000,
    variables: { isAcknowledged: false, locationId },
  });
  const { clientId } = useParams();

  const alarms =
    data?.alarms.length && data?.alarms.length > NUMBER_OF_ALARMS_TO_SHOW
      ? data?.alarms.slice(0, NUMBER_OF_ALARMS_TO_SHOW)
      : data?.alarms;
  const extractAlarmDescription = useCallback(
    (data?: AlarmDescriptionDto) => {
      if (!data) return "";
      const descriptionArguments = data?.descriptionArguments;

      return t(data?.descriptionCode, {
        "0": descriptionArguments?.length ? descriptionArguments[0] : "",
        "1":
          descriptionArguments?.length && descriptionArguments?.length > 0
            ? descriptionArguments[1]
            : "",
        "2":
          descriptionArguments?.length && descriptionArguments?.length > 1
            ? descriptionArguments[2]
            : "",
      });
    },
    [t],
  );

  const getMenuItemLabel = (
    alarm: AlarmDto,
    displayDivider: boolean,
  ): JSX.Element => {
    return (
      <Row style={{ width: "18rem" }}>
        <Col xs={23} md={12} style={{ alignItems: "center", display: "flex" }}>
          {getFormattedDate(alarm.timestamp, "DD-MM-YY HH:mm:ss")}
        </Col>
        <Col xs={1} md={1}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col xs={24} md={11}>
          <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
            {extractAlarmDescription(alarm.description)}
          </Paragraph>
        </Col>
        {displayDivider && <Divider style={{ margin: "0.875rem 0 0" }} />}
      </Row>
    );
  };

  const items: MenuProps["items"] = alarms?.length
    ? alarms.map((alarm, i) => ({
        disabled: alarm.isAcknowledged,
        key: alarm.id,
        label: getMenuItemLabel(alarm as AlarmDto, i !== alarms.length - 1),
        onClick: (menuItem) =>
          clientId &&
          navigate(AllRoutes.Client.DynamicPathWithTab(clientId, "alarms"), {
            state: { selectedAlarmId: menuItem.key },
          }),
        style: { fontWeight: alarm.isAcknowledged ? "normal" : "bold" },
        title: t("click_to_mark_as_read"),
      }))
    : [{ key: "1", label: t("no_alarms") }];

  return (
    <Dropdown menu={{ items }}>
      <Flex style={{ position: "absolute" }}>
        {!!data?.alarms.length && (
          <StyledAlarmsLengthBadge>
            {data.alarms.length}
          </StyledAlarmsLengthBadge>
        )}
        <StyledBellIcon />
      </Flex>
    </Dropdown>
  );
};
export default AlarmLogDropdown;
