import { FC, PropsWithChildren } from "react";

import { Layout } from "antd";
import styled from "styled-components";

import LayoutHeader from "components/Layout/LayoutHeader";
import { useAuth } from "store/auth/useAuth";
import { isValidToken } from "utils/authorizationUtils";

const { Content, Footer } = Layout;

const StyledLayout = styled(Layout)`
  height: 100vh;
  padding-bottom: 1rem;
`;
const StyledContent = styled(Content)`
  padding: 1rem 2rem 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
const StyledFooter = styled(Footer)`
  text-align: center;
  position: relative;
`;

const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const { token } = useAuth();

  return (
    <StyledLayout>
      {token && isValidToken(token) && <LayoutHeader />}
      <StyledContent className="ag-theme-balham">{children}</StyledContent>
      {/*<Divider style={{ marginBottom: 0, marginTop: "0.5rem" }} />*/}
      {/*{token && isValidToken(token) && (*/}
      {/*  <StyledFooter>{t("we_move_water")}</StyledFooter>*/}
      {/*)}*/}
    </StyledLayout>
  );
};

export default PageLayout;
