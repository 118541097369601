import { ValueFormatterParams } from "ag-grid-community";
import moment from "moment";

import { UserRoleEnum } from "graphql/main";

export const decimalNumberFormatter =
  (digits = 1) =>
  (params: ValueFormatterParams) => {
    return params.value.toFixed(digits);
  };

export const dateTimeFormatter = (params: ValueFormatterParams) =>
  moment(new Date(params.value)).format("DD-MM-YYYY");
export const userRoleEnumValueFormatter = (
  params: ValueFormatterParams,
  t: (arg: string) => any,
) => {
  if (params.value === UserRoleEnum.Admin) {
    return t("admin");
  } else {
    return t("client");
  }
};
export const dateTimeFormatterWithMin = (params: ValueFormatterParams) =>
  moment(new Date(params.value)).format("YYYY-MM-DD HH:mm:ss");
