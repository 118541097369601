import { FC, useCallback, useRef } from "react";

import { GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Checkbox, Flex, Space } from "antd";
import Search from "antd/lib/input/Search";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getGridDefaultProps } from "constants/gridConstants";
import { LocationDto, useGetLocationsQuery } from "graphql/main";
import AllRoutes from "screens/AllRoutes";
import useColDefs from "screens/Private/Location/LocationsGridView/useColDefs";

const LocationsGridView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gridRef = useRef<AgGridReact>(null);
  const { columnDefs } = useColDefs();
  const { data: locationsData, refetch } = useGetLocationsQuery({
    pollInterval: 3000,
  });

  const onSearch = useCallback(
    async (value: string) => await refetch({ searchText: value }),
    [refetch],
  );

  const onIsAlarmOnlyChange = useCallback(
    async (checked: boolean) =>
      await refetch({ alarmsOnly: checked || undefined }),
    [refetch],
  );

  return (
    <>
      <h1>{t("locations_grid_view")}</h1>
      <Flex justify="space-between">
        <Space size="large">
          <Search
            onSearch={onSearch}
            onChange={(e) => onSearch(e.target.value)}
            style={{ width: "15rem" }}
            placeholder={t("search")}
          />
          <Checkbox onChange={(e) => onIsAlarmOnlyChange(e.target.checked)}>
            {t("alarms_only")}
          </Checkbox>
        </Space>
      </Flex>

      <AgGridReact
        ref={gridRef}
        getRowId={(params: GetRowIdParams<LocationDto>) => params.data.id}
        getRowStyle={(params) =>
          params.data?.hasAlarm && { backgroundColor: "rgb(220, 20, 60, 0.4)" }
        }
        rowData={locationsData?.locations}
        columnDefs={columnDefs}
        onRowDoubleClicked={(event) =>
          navigate(AllRoutes.Client.DynamicPath(event.data?.clientId), {
            state: { location: event.data?.id },
          })
        }
        {...getGridDefaultProps()}
      />
    </>
  );
};
export default LocationsGridView;
