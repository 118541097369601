import { FC, useCallback, useState } from "react";

import { Button, Form, Input, message, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { CreateClientInput, useCreateClientMutation } from "graphql/main";

const CreateClientModal: FC<{
  onCreateSuccess?: () => void;
}> = ({ onCreateSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateClientInput>();
  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
    form.resetFields();
  };
  const [createClient] = useCreateClientMutation({
    onCompleted: () => {
      message.success(t("client_created_successfully"));
      toggleModalOpen();
      onCreateSuccess?.();
    },
  });

  const onFormSubmit = useCallback(
    async (values: CreateClientInput) =>
      await createClient({
        variables: { input: { ...values, imageUrl: "a" } },
      }),
    [createClient],
  );

  return (
    <>
      <Button type="primary" onClick={toggleModalOpen}>
        {t("new_client")}
      </Button>
      <Modal
        title={t("create_client")}
        open={modalOpen}
        onCancel={toggleModalOpen}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={onFormSubmit}>
          <Form.Item
            name="name"
            label={t("client_name")}
            labelCol={{ span: 24 }}
            rules={[{ message: t("field_required"), required: true }]}
          >
            <Input placeholder={t("client_name")} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default CreateClientModal;
