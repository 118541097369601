import { CSSProperties, FC } from "react";

import { StyledPageTitle } from "./styles";

type Props = {
  children: string;
  style?: CSSProperties;
  className?: string;
};

const PageTitle: FC<Props> = ({ children, style, className }) => {
  return (
    <StyledPageTitle style={style} className={`${className as string}`}>
      {children}
    </StyledPageTitle>
  );
};

export default PageTitle;
