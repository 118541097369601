import { FC } from "react";

import { cloneDeep } from "@apollo/client/utilities";
import { GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { getGridDefaultProps } from "constants/gridConstants";
import {
  AlarmDto,
  LocationByClientIdDto,
  useGetAlarmConfigurationsQuery,
} from "graphql/main";
import useAlarmsConfigurationColDefs from "screens/Private/Client/components/configuration/useAlarmsConfigurationColDefs";

const AlarmsConfigurationTab: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { data: alarmsData, refetch: refetchAlarms } =
    useGetAlarmConfigurationsQuery({
      variables: { locationId: locationId },
    });

  const { columnDefs } = useAlarmsConfigurationColDefs(refetchAlarms);

  return (
    <>
      <AgGridReact
        getRowId={(params: GetRowIdParams<AlarmDto>) => params.data.id}
        rowData={cloneDeep(alarmsData?.alarmConfigurations)}
        headerHeight={30}
        singleClickEdit
        rowHeight={28}
        columnDefs={columnDefs}
        {...getGridDefaultProps()}
      />
    </>
  );
};
export default AlarmsConfigurationTab;
