import { FC } from "react";

import { Route, Routes } from "react-router-dom";

import ClientsGrid from "screens/Private/ClientsGrid/ClientsGrid";
import ClientLocations from "screens/Private/Location/ClientLocations/ClientLocations";
import LocationsGridView from "screens/Private/Location/LocationsGridView/LocationsGridView";
import UsersGrid from "screens/Private/UsersGrid/UsersGrid";

import AllRoutes from "./AllRoutes";
import Client from "./Private/Client/Client";
import DashboardLocations from "./Private/Location/DashboardLocations/DashboardLocations";
import ForgotPassword from "./Public/ForgotPassword/ForgotPassword";
import Login from "./Public/Login/Login";

const Routing: FC = () => {
  return (
    <Routes>
      <Route
        path={AllRoutes.DashboardLocations}
        element={<DashboardLocations />}
      />
      <Route path={AllRoutes.Login} element={<Login />} />
      <Route path={AllRoutes.ForgotPassword} element={<ForgotPassword />} />
      <Route path={AllRoutes.Dashboard} element={<DashboardLocations />} />
      <Route path={AllRoutes.LocationsGrid} element={<LocationsGridView />} />
      <Route path={AllRoutes.Location.Path} element={<ClientLocations />} />
      <Route path={AllRoutes.Clients} element={<ClientsGrid />} />
      <Route path={AllRoutes.Users} element={<UsersGrid />} />
      <Route path={AllRoutes.Client.Path}>
        <Route index element={<Client />} />
      </Route>
    </Routes>
  );
};

export default Routing;
