import { FC, useRef } from "react";

import { Button, Modal } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { useTranslation } from "react-i18next";

import {
  ComponentDto,
  DistributionBoardSettingsDto,
  UpdateDistributionBoardSettingsInput,
} from "graphql/main";

export type SettingToUpdateType =
  | {
      description: ComponentDto["description"];
      slotName: ComponentDto["slotName"];
      value: ComponentDto["value"];
      valueToDisplay: string;
    }
  | undefined;
type Props = {
  isUpdateSettingModalOpen: boolean;
  boardId: DistributionBoardSettingsDto["id"];
  settingToUpdate?: SettingToUpdateType;
  saveEnabled?: boolean;
  onCancel: () => void;
  onOk: (props: UpdateDistributionBoardSettingsInput) => void;
};
const EditValueModal: FC<Props> = ({
  isUpdateSettingModalOpen,
  settingToUpdate,
  saveEnabled,
  onCancel,
  onOk,
  boardId,
}) => {
  const { t } = useTranslation();
  const okBtnRef = useRef<HTMLButtonElement>(null);
  const onOkHandler = () => {
    settingToUpdate?.slotName &&
      settingToUpdate.value &&
      onOk({
        distributionBoardId: boardId,
        setting: {
          slotName: settingToUpdate?.slotName,
          value: settingToUpdate?.value,
        },
      });
  };
  const footer = (
    <>
      <Button
        onClick={onOkHandler}
        disabled={!saveEnabled}
        type="primary"
        ref={okBtnRef}
      >
        {t("yes")}
      </Button>
      <Button onClick={onCancel}>{t("no")}</Button>
    </>
  );

  return (
    <Modal
      title={t("update_board_setting")}
      centered
      destroyOnClose
      afterOpenChange={(open) => open && okBtnRef.current?.focus()}
      open={isUpdateSettingModalOpen}
      footer={footer}
      onCancel={onCancel}
    >
      <Paragraph>{t("are_you_sure")}</Paragraph>
      <Paragraph strong>{settingToUpdate?.description}</Paragraph>
      <Paragraph>{t("with_this")}</Paragraph>
      <Paragraph strong>{settingToUpdate?.valueToDisplay}</Paragraph>
    </Modal>
  );
};
export default EditValueModal;
