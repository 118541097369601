import React from "react";

import { Navigate, useLocation } from "react-router-dom";

import AllRoutes from "screens/AllRoutes";
import { isLoggedIn } from "utils/authorizationUtils";

type Props = {
  publicRoutes: string[];
  children: JSX.Element | JSX.Element[];
};

const AuthGuard: React.FC<Props> = (props) => {
  const { pathname } = useLocation();

  const routeIsPublic = props.publicRoutes.includes(pathname);
  let result: JSX.Element | JSX.Element[];
  if (isLoggedIn() || routeIsPublic) {
    result = props.children;
  } else {
    result = <Navigate to={AllRoutes.Login} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{result}</>;
};

export default AuthGuard;
