import { FC } from "react";

import { Tabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";

import { LocationByClientIdDto } from "graphql/main";
import AlarmsHistoryTab from "screens/Private/Client/components/alarms/AlarmsHistoryTab";
import AlarmsTab from "screens/Private/Client/components/alarms/AlarmsTab";
const AlarmsInnerTabs: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { t } = useTranslation();
  const items: TabsProps["items"] = [
    {
      children: <AlarmsTab locationId={locationId} />,
      key: "alarms",
      label: t("alarms_tab"),
      style: { height: "100%" },
    },
    {
      children: <AlarmsHistoryTab locationId={locationId} />,
      key: "alarms-history",
      label: t("alarms_history"),
    },
  ];

  return (
    <Tabs
      destroyInactiveTabPane
      tabPosition="left"
      style={{ height: "100%" }}
      items={items}
    />
  );
};
export default AlarmsInnerTabs;
