import { FC } from "react";

import { useTranslation } from "react-i18next";

const ClientLocations: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("locations")}</h1>
    </>
  );
};

export default ClientLocations;
