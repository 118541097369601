import { jwtDecode, JwtPayload } from "jwt-decode";

import restApiRoutes from "config/restApiRoutes";
import LOCAL_STORAGE_CONSTANTS from "constants/authorizationLocalStorage";

import { getCacheVal, removeCacheVal, setCacheVal } from "./cacheUtils";

export const login = (token: string, refreshToken: string) => {
  const exp = jwtDecode<JwtPayload>(token).exp!;
  setCacheVal(LOCAL_STORAGE_CONSTANTS.Token, token.toString());
  setCacheVal(LOCAL_STORAGE_CONSTANTS.RefreshToken, refreshToken.toString());
  setCacheVal(LOCAL_STORAGE_CONSTANTS.TokenExpirationTime, exp.toString());
};

export const getToken = (): string =>
  getCacheVal(LOCAL_STORAGE_CONSTANTS.Token);

export const clearStorageTokenValues = () => {
  removeCacheVal(LOCAL_STORAGE_CONSTANTS.Token);
  removeCacheVal(LOCAL_STORAGE_CONSTANTS.RefreshToken);
  removeCacheVal(LOCAL_STORAGE_CONSTANTS.IsPassExpired);
  removeCacheVal(LOCAL_STORAGE_CONSTANTS.TokenExpirationTime);
};

export const passExpiredHandler = (isExpired: boolean) => {
  setCacheVal(LOCAL_STORAGE_CONSTANTS.IsPassExpired, isExpired.toString());
};

export const getCurrentUserEmail = (): string => {
  const token = getToken();

  if (token !== "") {
    return jwtDecode<JwtPayload>(token)[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress" as keyof JwtPayload
    ]!.toString();
  }

  return "";
};

export const getCurrentUserName = (): string => {
  const token = getToken();

  if (token !== "") {
    return jwtDecode<JwtPayload>(token)[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name" as keyof JwtPayload
    ]!.toString();
  }

  return "";
};

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded: JwtPayload = jwtDecode(accessToken);
  const currentDate = new Date();

  return decoded.exp! * 1000 > currentDate.getTime();
};

export const refreshToken = async () => {
  const refreshToken = getCacheVal(LOCAL_STORAGE_CONSTANTS.RefreshToken);

  if (refreshToken) {
    const formData = new FormData();
    formData.append(
      LOCAL_STORAGE_CONSTANTS.RefreshToken,
      refreshToken.toString(),
    );
    const requestOptions = {
      body: JSON.stringify(refreshToken),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    };

    const response = await fetch(
      restApiRoutes.refreshTokenConfigs.refreshTokenUrl,
      requestOptions,
    );
    if (!response.ok) {
      clearStorageTokenValues();

      return null;
    }

    return await response.json();
  }

  return null;
};

export const isTokenNearToExpire = (token: string) => {
  const exp = jwtDecode<JwtPayload>(token).exp!;

  const currentTime = new Date().getTime();

  return (
    exp * 1000 - currentTime <
    restApiRoutes.refreshTokenConfigs.closeToTokenExpirationTime
  );
};

export const autoTokenRefresh = () => {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  setTimeout(async () => {
    const newToken = (await refreshToken())?.token;
    if (newToken) {
      setCacheVal(LOCAL_STORAGE_CONSTANTS.Token, newToken);
    }

    autoTokenRefresh();
  }, restApiRoutes.refreshTokenConfigs.closeToTokenExpirationTime); // callback
};

export const isLoggedIn = (): boolean => isValidToken(getToken());
