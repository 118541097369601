import { FC } from "react";

import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Flex, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { LocationDto } from "graphql/main";
import AllRoutes from "screens/AllRoutes";

const LocationsRow = styled(Row)`
  width: 100%;
  margin-top: 2rem;
  overflow-y: hidden;
  &:hover {
    overflow-y: auto;
    transition-duration: 1s;
  }
`;
const StyledCol = styled(Col)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const StyledAvatar = styled(Avatar)<{ $isActive: boolean; $hasAlarm: boolean }>`
  background-color: ${({ $hasAlarm, $isActive }) =>
    $hasAlarm ? "crimson" : $isActive ? "#3d3d3d" : "darkgray"};

  opacity: ${({ $hasAlarm, $isActive }) =>
    !$hasAlarm && $isActive ? undefined : 0.4};

  margin-right: 1rem;
`;
const StyledSpan = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;
const ContractsContainer: FC<{
  locations?: LocationDto[];
  isActive: boolean;
}> = ({ locations, isActive }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return locations ? (
    <Flex vertical align="center" style={{ width: "100%" }}>
      <StyledSpan>
        {isActive ? t("active_contracts") : t("inactive_contracts")}
      </StyledSpan>
      <LocationsRow gutter={[20, 20]}>
        {locations.map((location) => (
          <StyledCol
            xs={24}
            md={12}
            onClick={() =>
              navigate(AllRoutes.Client.DynamicPath(location.clientId), {
                state: { location: location.id },
              })
            }
            key={location.id}
          >
            <StyledAvatar
              $isActive={isActive}
              $hasAlarm={location.hasAlarm}
              size="large"
              shape="square"
              icon={<UserOutlined />}
            />
            <span>{location.name}</span>
          </StyledCol>
        ))}
      </LocationsRow>
    </Flex>
  ) : null;
};
export default ContractsContainer;
