import React from "react";

import "./App.css";
import { ApolloProvider } from "@apollo/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import PageLayout from "components/Layout/PageLayout";
import { apolloClient } from "config/apollo-client";
import AuthGuard from "guards/AuthGuard";
import i18n from "i18n";
import PUBLIC_ROUTES from "screens/PublicRoutes";
import Routing from "screens/Routing";
import { AuthProvider } from "store/auth/useAuth";

const App = () => {
  return (
    <AuthProvider>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <AuthGuard publicRoutes={PUBLIC_ROUTES}>
              <PageLayout>
                <Routing />
              </PageLayout>
            </AuthGuard>
          </BrowserRouter>
        </I18nextProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
