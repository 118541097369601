import { useMemo } from "react";

import { ColDef } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import { dateTimeFormatter } from "components/UI/agGrid/valueFormatters";
import { booleanValueRenderer } from "components/UI/agGrid/valueRenderers";
import { COLUMN_TYPE_NAMES } from "constants/gridConstants";
import { LocationDto } from "graphql/main";

const useColDefs = () => {
  const { t } = useTranslation();
  const columnDefs = useMemo<ColDef<LocationDto>[]>(
    () => [
      {
        field: "name",
        headerName: t("name"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "address",
        headerName: t("address"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "mobile",
        headerName: t("mobile"),
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "contractDate",
        headerName: t("contractDate"),
        valueFormatter: dateTimeFormatter,
      },
      { field: "distributionBoardId", headerName: t("distributionBoardId") },
      {
        cellRenderer: booleanValueRenderer,
        field: "hasActiveContract",
        headerName: t("hasActiveContract"),
      },
    ],
    [t],
  );

  return { columnDefs };
};

export default useColDefs;
