import { useCallback, useMemo } from "react";

import { ApolloQueryResult } from "@apollo/client";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { dateTimeFormatterWithMin } from "components/UI/agGrid/valueFormatters";
import { COLUMN_TYPE_NAMES } from "constants/gridConstants";
import {
  AlarmDescriptionDto,
  AlarmDto,
  GetAlarmsHistoryQuery,
  GetAlarmsQuery,
  useUpdateAlarmMutation,
} from "graphql/main";

const useAlarmsColDefs = (
  refetchAlarms?: () => Promise<
    ApolloQueryResult<GetAlarmsHistoryQuery | GetAlarmsQuery>
  >,
) => {
  const [t] = useTranslation();

  const [updateAlarm, { loading }] = useUpdateAlarmMutation({
    onCompleted: () => refetchAlarms?.(),
  });
  const extractAlarmDescription = useCallback(
    (data?: AlarmDescriptionDto) => {
      if (!data) return "";
      const descriptionArguments = data?.descriptionArguments;

      return t(data?.descriptionCode, {
        "0": descriptionArguments?.length ? descriptionArguments[0] : "",
        "1":
          descriptionArguments?.length && descriptionArguments?.length > 0
            ? descriptionArguments[1]
            : "",
        "2":
          descriptionArguments?.length && descriptionArguments?.length > 1
            ? descriptionArguments[2]
            : "",
      });
    },
    [t],
  );

  const columnDefs = useMemo<ColDef<AlarmDto>[]>(
    () => [
      {
        field: "timestamp",
        headerName: t("timestamp"),
        pinned: "left",
        valueFormatter: dateTimeFormatterWithMin,
      },
      {
        field: "code",
        headerName: t("code"),
        maxWidth: 100,
      },
      {
        field: "description",
        headerName: t("description"),
        valueGetter: (params) =>
          extractAlarmDescription(params.data?.description),
      },
      {
        field: "isActive",
        headerName: t("active"),
        maxWidth: 100,
        type: COLUMN_TYPE_NAMES.BOOLEAN_COLUMN,
      },
      {
        cellRenderer: (params: ICellRendererParams<AlarmDto>) => (
          <Button
            loading={loading}
            onClick={() =>
              updateAlarm({
                variables: {
                  input: { id: params.data?.id, isAcknowledged: true },
                },
              })
            }
            type="primary"
            disabled={params.data?.isAcknowledged}
          >
            {t(params.data?.isAcknowledged ? "acknowledged" : "acknowledge")}
          </Button>
        ),
        filter: false,
        headerName: t("acknowledge"),
        maxWidth: 200,
        sortable: false,
      },
    ],
    [extractAlarmDescription, updateAlarm, t, loading],
  );
  const historyColumnDefs = useMemo<ColDef<AlarmDto>[]>(
    () => [
      {
        field: "timestamp",
        headerName: t("timestamp"),
        pinned: "left",
        valueFormatter: dateTimeFormatterWithMin,
      },
      {
        field: "code",
        headerName: t("code"),
        maxWidth: 100,
      },
      {
        field: "description",
        headerName: t("description"),
        valueGetter: (params) =>
          extractAlarmDescription(params.data?.description),
      },
      {
        field: "status",
        headerName: t("status"),
        maxWidth: 180,
        valueGetter: (params) => t(params.data?.status),
      },
    ],
    [extractAlarmDescription, t],
  );

  return { columnDefs, historyColumnDefs };
};

export default useAlarmsColDefs;
