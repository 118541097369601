import moment from "moment";

export const isExpired = (expiration: Date) => {
  const now = new Date().getTime();
  const expirationTime = new Date(expiration).getTime();
  if (!expiration) {
    return true;
  }
  if (expirationTime && expirationTime < now) {
    return true;
  }

  return false;
};

export const subtractYears = (numOfYears: number, date = new Date()) => {
  const dateCopy = new Date(date.getTime());

  dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);

  return dateCopy;
};
export const getTimeFromNow = (inputDate: Date) => moment(inputDate).fromNow();
export const getFormattedDate = (inputDate: Date, format?: string) =>
  moment(inputDate).format(format || "DD MMM YYYY");

export enum dateAmountType {
  DAYS,
  WEEKS,
  MONTHS,
  YEARS,
}

export const addDate = (
  dt: Date,
  amount: number,
  dateType: dateAmountType,
): any => {
  switch (dateType) {
    case dateAmountType.DAYS:
      return dt.setDate(dt.getDate() + amount) && dt;
    case dateAmountType.WEEKS:
      return dt.setDate(dt.getDate() + 7 * amount) && dt;
    case dateAmountType.MONTHS:
      return dt.setMonth(dt.getMonth() + amount) && dt;
    case dateAmountType.YEARS:
      return dt.setFullYear(dt.getFullYear() + amount) && dt;
  }
};
