import { FC, useState } from "react";

import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import logo from "assets/images/hydrokraft_logo.svg";

import {
  AuthButton,
  AuthButtonsContainer,
  AuthForm,
  AuthFormContainer,
  AuthHeader,
  AuthHeaderContainer,
} from "./styles";

const ForgotPassword: FC = () => {
  const { t } = useTranslation("translation");
  const [loading, setLoading] = useState<boolean>(false);

  type FormValues = {
    email: string;
  };

  const onFinish = (values: FormValues) => {
    setLoading(true);
    // dispatchLogin({
    //   email: values.email,
    //   password: values.password,
    //   rememberMe,
    // })
    //   .then(async (response) => {
    //     const data = await response.json();
    //
    //     if (!response.ok) {
    //       return await Promise.reject(data);
    //     }
    //
    //     const token = data.token;
    //     setToken(token);
    //     const refreshToken = data.refreshToken;
    //
    //     login(token, refreshToken);
    //     clearError();
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     setError(err, ErrorTypeEnum.LoginError);
    //
    //     if (err?.errors?.PASSWORD_EXPIRED) {
    //       const token = err?.errors?.PASSWORD_EXPIRED[0];
    //       const refreshToken = err?.errors?.PASSWORD_EXPIRED[1];
    //       setToken(token);
    //       passExpiredHandler(true);
    //       login(token, refreshToken);
    //       clearError();
    //     }
    //   });
  };

  // useEffect(() => {
  //   if (token && isF2RCMC && canAccessSRRoutes) {
  //     navigate(ROUTES.FullPaths.AgreementList);
  //
  //     return;
  //   }
  //
  //   if (token && role && (canAccessAdminRoutes || canAccessSRRoutes)) {
  //     setLoading(false);
  //     navigate(ROUTES.Default);
  //   }
  // }, [token, role]); // eslint-disable-line

  return (
    <AuthFormContainer>
      <AuthHeaderContainer>
        <img src={logo} width={300} height={250} alt="logo" />
        <AuthHeader>{t("forgot_password")}</AuthHeader>
      </AuthHeaderContainer>
      <AuthForm
        layout={"vertical"}
        name="basic"
        labelWrap
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[
            {
              message: t("invalid_email"),
              required: true,
              type: "email",
            },
          ]}
        >
          <Input
            type="email"
            placeholder={t("enter_email")}
            size="large"
            autoComplete="username"
          />
        </Form.Item>

        <AuthButtonsContainer>
          <AuthButton
            htmlType="submit"
            title={t("send")}
            type="primary"
            loading={loading}
          >
            {t("send")}
          </AuthButton>
        </AuthButtonsContainer>
      </AuthForm>
    </AuthFormContainer>
  );
};

export default ForgotPassword;
