import { FC, useCallback } from "react";

import { Checkbox, Divider, Flex, Space } from "antd";
import Search from "antd/lib/input/Search";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useGetLocationsQuery } from "graphql/main";
import ContractsContainer from "screens/Private/Location/DashboardLocations/ContractsContainer";

const StyledDivider = styled(Divider)`
  height: 100%;
  margin: 0 2rem;
`;

const DashboardLocations: FC = () => {
  const { t } = useTranslation();
  const { data: activeContracts, refetch: refetchActive } =
    useGetLocationsQuery({
      pollInterval: 3000,
      variables: { hasActiveContract: true },
    });

  const { data: inactiveContracts, refetch: refetchInactive } =
    useGetLocationsQuery({
      pollInterval: 3000,
      variables: { hasActiveContract: false },
    });
  const onSearch = useCallback(
    async (value: string) => {
      await refetchActive({ searchText: value });
      await refetchInactive({ searchText: value });
    },
    [refetchActive, refetchInactive],
  );
  const onIsAlarmOnlyChange = useCallback(
    async (checked: boolean) => {
      await refetchActive({ alarmsOnly: checked || undefined });
      await refetchInactive({ alarmsOnly: checked || undefined });
    },
    [refetchActive, refetchInactive],
  );

  return (
    <>
      <h1>{t("locations")}</h1>

      <Flex justify="space-between">
        <Space size="large">
          <Search
            onSearch={onSearch}
            onChange={(e) => onSearch(e.target.value)}
            style={{ width: "15rem" }}
            placeholder={t("search")}
          />
          <Checkbox onChange={(e) => onIsAlarmOnlyChange(e.target.checked)}>
            {t("alarms_only")}
          </Checkbox>
        </Space>
      </Flex>

      <Divider type="horizontal" />

      <Flex style={{ marginTop: "1rem" }}>
        <ContractsContainer locations={activeContracts?.locations} isActive />
        <StyledDivider type="vertical" />
        <ContractsContainer
          locations={inactiveContracts?.locations}
          isActive={false}
        />
      </Flex>
    </>
  );
};

export default DashboardLocations;
