export type ClientTabsType =
  | "alarms"
  | "communications"
  | "configuration"
  | "general"
  | "history"
  | "metrics"
  | "tech-diagram";
export default {
  Client: {
    AlarmsTab: "alarms",
    DynamicPath: (clientId: string) => `/client/${clientId}`,
    DynamicPathWithTab: (clientId: string, selectedTab?: ClientTabsType) =>
      `/client/${clientId}/${selectedTab}`,
    Path: "/client/:clientId/:selectedTab?",
    SelectedTab: ":selectedTab",
  },
  Clients: "/clients",
  Dashboard: "/",
  DashboardLocations: "/dashboard-locations",
  ForgotPassword: "/forgot-password",
  Location: {
    DynamicPath: (clientId: string) => `/client/${clientId}/locations`,
    Path: "/client/:clientId/locations",
  },
  LocationsGrid: "/grid-locations",
  Login: "/login",
  Users: "/users",
};
