import { FC } from "react";

import { GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Flex, Select } from "antd";
import { useTranslation } from "react-i18next";

import { getGridDefaultProps } from "constants/gridConstants";
import {
  AlarmDto,
  LocationByClientIdDto,
  useGetAlarmComponentsQuery,
  useGetAlarmsHistoryQuery,
} from "graphql/main";
import useAlarmsColDefs from "screens/Private/Client/components/alarms/useAlarmsColDefs";

const AlarmsTab: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { t } = useTranslation();
  const { data: alarmsData, refetch: refetchAlarms } = useGetAlarmsHistoryQuery(
    {
      pollInterval: 10000,
      variables: { locationId: locationId },
    },
  );
  const { data: components } = useGetAlarmComponentsQuery({
    variables: { locationId: locationId },
  });
  const { historyColumnDefs: columnDefs } = useAlarmsColDefs();

  return (
    <>
      <Flex align="center">
        <span>{t("filter_results_by")}</span>
        <Select
          allowClear
          placeholder={t("select_option")}
          style={{ marginLeft: "1rem", minWidth: "20rem" }}
          options={components?.alarmComponents.map((component) => ({
            label: component.description,
            value: component.slotName,
          }))}
          onChange={(value) => refetchAlarms({ locationId, slotName: value })}
        />
      </Flex>
      <AgGridReact
        getRowId={(params: GetRowIdParams<AlarmDto>) => params.data.id}
        rowData={alarmsData?.alarmsHistory}
        headerHeight={30}
        rowHeight={28}
        columnDefs={columnDefs}
        {...getGridDefaultProps()}
      />
    </>
  );
};
export default AlarmsTab;
