import { Dayjs } from "dayjs";

import { LocationDto } from "graphql/main";

import envConfig from "./envConfig";

export default {
  AUTH_LOGIN: `${envConfig.apiBackend!}/api/Auth/Login`,
  EXPORT_HISTORIES: (
    locationId: LocationDto["id"],
    fromDate: Dayjs,
    toDate: Dayjs,
    columns: string,
  ) =>
    `${envConfig.apiBackend!}/api/File/ExportHistories?locationId=${locationId}&fromDate=${fromDate}&toDate=${toDate}&columns=${columns}`,
  refreshTokenConfigs: {
    closeToTokenExpirationTime: 39600000,
    refreshTokenUrl: `${envConfig.apiBackend!}/api/Auth/RefreshToken`,
  },
};
