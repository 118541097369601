import { useMemo } from "react";

import { ColDef } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import { dateTimeFormatterWithMin } from "components/UI/agGrid/valueFormatters";
import { GetProcessedDataHeaderNamesQuery, HistoryDto } from "graphql/main";

const useHistoryColDefs = (
  headerNames: GetProcessedDataHeaderNamesQuery | undefined,
) => {
  const [t] = useTranslation();

  const columnDefs = useMemo<ColDef<HistoryDto>[]>(
    () => [
      {
        field: "timestamp",
        headerName: t("timestamp"),
        minWidth: 160,
        pinned: "left",
        valueFormatter: dateTimeFormatterWithMin,
      },
    ],
    [t],
  );

  const columnDefsWithHeaders = useMemo<ColDef<HistoryDto>[]>(() => {
    return headerNames?.processedDataHeaderNames
      ? columnDefs.concat(
          headerNames?.processedDataHeaderNames.map(
            (header) =>
              ({
                field: `formattedData.${header.slotName}`,
                headerName: header.description,
                headerTooltip: header.description,
              }) as ColDef<HistoryDto>,
          ),
        )
      : columnDefs;
  }, [columnDefs, headerNames]);

  return { columnDefs: columnDefsWithHeaders };
};

export default useHistoryColDefs;
