import "./index.css";
import React, { StrictMode } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

import { ConfigProvider } from "antd";
import locale from "antd/locale/en_GB";
import dayjs from "dayjs";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "dayjs/locale/en-gb";

dayjs.locale("en-GB");

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
