import { Button, Form } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import PageTitle from "components/UI/PageTitle";

export const AuthFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 25rem;
`;

export const AuthHeaderContainer = styled.div`
  text-align: center;
  min-height: 10rem;
  padding: 0 3rem;
`;

export const AuthHeader = styled(PageTitle)`
  margin-bottom: 2rem;
`;

export const AuthForm = styled(Form)`
  width: 100%;
`;

export const AuthLink = styled(Link)`
  display: block;
  text-align: center;
  font-size: 16px;
  color: #626262;
`;

export const AuthButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AuthButton = styled(Button)`
  width: 200px;
  margin-bottom: 2rem;
`;
